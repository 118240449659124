<template>
  <base-form-item
      :field="field"
      :schemaVersion="schemaVersion"
  >
    <static-table
        :data="value"
        :params="params"
        :thead="thead"
        v-if="value"
    ></static-table>
    <span
        class="no-date-title"
        v-else
    >{{ $t('system.no_data') }}</span>
  </base-form-item>
</template>

<script>
import StaticTable from '../../../../ABtable/StaticTable.vue';
import abstractForm from '../../mixin/index';
import BaseFormItem from '../../UI/FormItem.vue';

export default {
  mixins: [abstractForm],
  name: 'index',
  components: {
    StaticTable,
    BaseFormItem,
  },
  data() {
    return {
      model: {
        'results': [
          {
            'diseaseId': 'S_32',
            'result': 'N',
            'disease': 'PEDV',
          },
          {
            'diseaseId': 'S_33',
            'result': 'N',
            'disease': 'TGEV',
          },
          {
            'diseaseId': 'S_34',
            'result': 'N',
            'disease': 'Rotavirus (PRVA)',
          },
        ],
        'errors': [
          {},
        ],
      },
      thead: [
        {
          label: 'system.diseaseName',
          key: 'disease',
          translate: false,
          translateKey: 'diseaseName',
          sort: false,
        },
        {
          label: 'system.result',
          key: 'result',
          translate: true,
          translateKey: 'result',
          sort: false,
        },
      ],
      params: {
        column: 'id',
        direction: 'desc',
        per_page: 10,
        page: 1,
      },
      total: null,
    };
  },
  beforeMount() {
    if (this.value && this.value.results) {
      this.value = JSON.parse(JSON.parse(this.value.results)).results;
    }
  },
  methods: {
    //
  },
};
</script>
<style
    lang="scss"
    rel="stylesheet/scss"
>
</style>
